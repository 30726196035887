import React from "react";

import Hero from "../components/Hero";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import JobAd from "../components/JobAd";

const IndexPage = () => (
  <Layout>
    <SEO title="West Launch" keywords={[`startup`]} />
    <Hero />
    <JobAd />
  </Layout>
);

export default IndexPage;
