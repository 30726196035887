import React from "react";
import { StyledContainer } from "./styles";
import { Button } from "@material-ui/core";

const JobAd = () => (
  <StyledContainer id="details">
    <small>Los Angeles</small>
    <h1>We are looking for founders</h1>
    <p>
      West Launch LLC is looking for founders to partner with in creating new
      businesses. Our value add lies in our years-long experience in business,
      specifically software and tech, our existing business network that
      facilitates idea validation, our funding power, and our people. We treat
      our investments with passion, love and respect and push through rainy and
      sunny days.
    </p>

    <h2>The ideal founder(s) should</h2>
    <ul>
      <li>Be ambitious thinkers</li>
      <li>
        Have a business idea or not: we will add our brainpower and business
        network to brainstorm with you and speed up product market fit
      </li>
      <li>Embrace failures and learn from them</li>
      <li>
        Value honesty and transparency as the basis for efficient progress
      </li>
      <li>
        Be willing to argue about anything and listen to opposite positions
      </li>
      <li>Be empathic, friendly and nice, not an a*hole</li>
    </ul>

    <h2>Methods and technologies our teams in other startups currently use</h2>
    <ul>
      <li>Lean</li>
      <li>Agile (e.g. Eric Ries lean startup)</li>
      <li>Kanban</li>
    </ul>

    <h2>For Techies</h2>
    <ul>
      <li>Pair Programming</li>
      <li>Test-Driven Development</li>
      <li>Continuous Delivery of Docker Container</li>
      <li>Microservice architecture with Python</li>
      <li>Single-Page Apps with React</li>
      <li>Native Apps with Flutter</li>
    </ul>

    <p>We’d be happy to hear from you via email</p>
    <Button
      style={{ margin: 10 }}
      href="mailto:armand.zohari18@gmail.com"
      variant="contained"
      color="secondary"
    >
      Mail
    </Button>
  </StyledContainer>
);

export default JobAd;
