import { StaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";

import Container from "../Container";

const HeroContent = () => (
  <>
    <Container>
      <h1>
        Launchpad for your <nobr> business idea</nobr>
      </h1>
      <p>
        <nobr>We add business experience,</nobr>
        <nobr>funding and software skills</nobr>
      </p>
      <Button href="#details" variant="contained" color="secondary">
        Contact Us
      </Button>
    </Container>
  </>
);

const HeroBackground = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "hero-bg.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1500) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          // backgroundColor={`#040e18`}
          classId="hero-bg"
        >
          <HeroContent />
        </BackgroundImage>
      );
    }}
  />
);

const StyledHero = styled(HeroBackground)`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  flex-direction: column;

  &.gatsby-background-image-hero-bg {
    &:after,
    &:before {
      background-position: center center;
    }
  }
`;

const Hero = () => <StyledHero />;

export default Hero;
